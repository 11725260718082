import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

/**
 * PaytrailResultPage displays the result after payment.
 * It will also attempt to handover the payment receipt to the backend.
 */
export const PaytrailResultPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isSuccess, setIsSuccess] = React.useState<boolean>(true)

  useEffect(() => {
    setIsSuccess(searchParams.get('checkout-status') === 'ok')
  }, [searchParams])

  /**
   * Attempts to send the payment receipt
   */
  const renderSuccess = (): JSX.Element => {
    return (
      <>
        <Typography variant='h1'>{t('successPage.title')}</Typography>
        <Box mt={4} />
        <Typography variant='body1'>{t('successPage.desc')}</Typography>
      </>
    )
  }

  const renderCancel = (): JSX.Element => {
    return (
      <>
        <Typography variant='h1'>{t('cancelPage.title')}</Typography>
        <Box mt={4} />
        <Typography variant='body1'>{t('cancelPage.desc')}</Typography>
      </>
    )
  }

  return isSuccess ? renderSuccess() : renderCancel()
}
