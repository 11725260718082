import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { config } from '../../config'
import { useAuth } from '../../hooks/use-auth'
import { Box, Button, Typography } from '@mui/material'

export const LoginPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { state, login } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (state.isAuthenticated) {
      navigate(config.appRoutes.deposit, { replace: true })
    }
  }, [state.isAuthenticated])

  return (
    <>
      <Typography variant='h1'>{t('loginPage.title')}</Typography>
      <Box mt={4} />
      <Typography variant='body1'>{t('loginPage.desc1')}</Typography>
      <Typography variant='body1'>{t('loginPage.desc2')}</Typography>
      <Box mt={8} />
      <Button color='primary' onClick={login}>
        {t('common.button.authenticate')}
      </Button>
    </>
  )
}
