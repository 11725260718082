import React from 'react'
import { AppLayout } from './components/app-layout/app-layout.component'
import { AppRoutes } from './components/app-routes/app-routes.component'

function App(): JSX.Element {
  return (
    <AppLayout>
      <AppRoutes />
    </AppLayout>
  )
}

export default App
