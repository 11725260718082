import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { config } from '../../config'
import { useAuth } from '../../hooks/use-auth'
import { AppBar, Link, Toolbar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    position: 'static',
    marginTop: 'auto',
  },
  toolbar: {
    justifyContent: 'center',
  },
  link: {
    marginLeft: theme.spacing(2),
    fontSize: '0.75rem',
    color: theme.palette.action.hover,
  },
}))

export const AppFooter: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { state, logout } = useAuth()

  return (
    <AppBar
      component='footer'
      color='transparent'
      elevation={0}
      className={classes.footer}
    >
      <Toolbar className={classes.toolbar}>
        <Link
          underline='none'
          href='https://www.smartum.fi/'
          target='_blank'
          rel='noopener noreferrer'
          className={classes.link}
        >
          smartum.fi
        </Link>
        <Link
          underline='none'
          href='https://support.smartum.fi/'
          target='_blank'
          rel='noopener noreferrer'
          className={classes.link}
        >
          {t('appFooter.link.customerSupport')}
        </Link>
        {state.isAuthenticated && (
          <Link
            underline='none'
            onClick={async () => {
              await logout()
              navigate(config.appRoutes.login)
            }}
            className={classes.link}
          >
            {t('appFooter.link.logout')}
          </Link>
        )}
      </Toolbar>
    </AppBar>
  )
}
