import React, { FormEvent, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { LoadingBackdrop } from '../../components'
import { config } from '../../config'
import { InitializeDepositRequest } from '../../services/deposit/models'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import { useNavigate } from 'react-router-dom'
import { useDeposit } from '../../hooks/use-deposit/use-deposit'
import { useAuth } from '../../hooks'
import { useValidation } from '../../utils/validationRules'
import { toCents } from '../../utils/amountToCents'
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { makeStyles } from 'tss-react/mui'
import DepositInfoModal from './deposit.infoModal'

const useStyles = makeStyles()(theme => ({
  roundedAlert: {
    borderRadius: 12,
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '10px',
  },
  infoModalLink: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '2px',
    color: theme.palette.text.secondary,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  emailInfoText: {
    color: theme.palette.text.primary,
    fontSize: '0.9rem',
  },
  amountInputField: {
    fontSize: '2.25rem',
    fontWeight: 600,
    justifyContent: 'center',
    alignItems: 'center',
  },
  amountInputAdornment: {
    fontSize: '2.25rem',
    color: theme.palette.text.primary,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.25rem',
    },
  },
}))

export const DepositPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { maxDepositAmount, initializeDeposit, error, loading } = useDeposit()
  const { logout } = useAuth()
  const { classes } = useStyles()
  const { validateEmail, isValidAmountFormat, validateDepositAmount } =
    useValidation()

  const [amount, setAmount] = useState<{ cents: number; str: string }>({
    cents: 0,
    str: '',
  })
  const [email, setEmail] = useState('')
  const [inputErrors, setInputErrors] = useState<{
    email?: string
    amount?: string
  }>({})

  const [openInfoModal, setOpenInfoModal] = useState(false)

  const handleOpenInfoModal = () => setOpenInfoModal(true)
  const handleCloseInfoModal = () => setOpenInfoModal(false)

  React.useEffect(() => {
    if (error) {
      if (error.status === 401) {
        logout()
        navigate('/error')
      }
    }
  }, [error])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const emailError = validateEmail(email)
      const amountError = validateDepositAmount(amount.cents, maxDepositAmount)

      if (email) {
        setInputErrors(prev => ({
          ...prev,
          email: emailError,
        }))
      }
      if (amount.str) {
        setInputErrors(prev => ({
          ...prev,
          amount: amountError,
        }))
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [email, amount.str])

  const amountHelpetText = !loading
    ? inputErrors.amount || t('depositPage.amountHelper', { maxDepositAmount })
    : ''

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (loading) return

    if (maxDepositAmount <= 0) {
      return
    }

    const emailError = validateEmail(email)
    const amountError = validateDepositAmount(amount.cents, maxDepositAmount)

    if (emailError || amountError) {
      setInputErrors({ email: emailError, amount: amountError })
      return
    }

    const params: InitializeDepositRequest = {
      email: email,
      amount: amount.cents,
      redirectUrls: {
        success: window.location.origin + config.appRoutes.success,
        cancel: window.location.origin + config.appRoutes.cancel,
      },
    }

    initializeDeposit(params).then(res => {
      window.location.replace(res.data.href)
    })
  }

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    value = value.replace('.', ',')
    const validInput = isValidAmountFormat(value)
    const numValueCents = toCents(value)
    if (validInput) {
      setAmount({ cents: numValueCents, str: value })
    }
  }

  const InfoModalLink = () => {
    return (
      <>
        <Link
          variant='body2'
          onClick={e => {
            handleOpenInfoModal()
            e.currentTarget.blur()
          }}
          className={classes.infoModalLink}
        >
          {t('depositPage.infoModalLink')}
          <HelpOutlineOutlinedIcon
            fontSize='small'
            sx={{
              padding: '1px 0px',
              verticalAlign: 'middle',
            }}
          />
        </Link>
      </>
    )
  }

  return (
    <>
      <Typography variant='h1'>{t('depositPage.title')}</Typography>
      <Box mt={2} />
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Typography variant='body2' textAlign='center'>
            <Trans
              i18nKey='depositPage.info'
              components={{
                link: <InfoModalLink />,
              }}
            />
          </Typography>

          {maxDepositAmount <= 0 && (
            <Grid size={{ xs: 12 }}>
              <Alert severity='error' className={classes.roundedAlert}>
                {t('depositPage.error.ownWalletFull')}
              </Alert>
            </Grid>
          )}
          <Grid size={{ xs: 12 }}>
            <TextField
              id='amount'
              className={classes.amountInputField}
              inputRef={input => {
                if (input !== null && !email) {
                  input.focus()
                }
              }}
              name='amount'
              helperText={amountHelpetText}
              error={!!inputErrors.amount}
              value={amount.str}
              onChange={handleAmountInputChange}
              required
              type='text'
              variant='standard'
              slotProps={{
                input: {
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography className={classes.amountInputAdornment}>
                        {'€'}
                      </Typography>
                    </InputAdornment>
                  ),
                },
                htmlInput: {
                  max: maxDepositAmount,
                  step: '0.01',
                  inputMode: 'decimal',
                  style: {
                    textAlign: 'right',
                    fontSize: '2.25rem',
                    fontWeight: 600,
                    width: '52%',
                  },
                },
              }}
              disabled={loading || maxDepositAmount <= 0}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography className={classes.emailInfoText}>
              {t('common.emailInfo')}
            </Typography>
            <Box mt={1} />
            <TextField
              id='email'
              fullWidth
              placeholder={t('common.email') || ''}
              name='email'
              variant='filled'
              sx={{ maxWidth: '300px' }}
              slotProps={{
                input: {
                  disableUnderline: true,
                },
                htmlInput: {
                  style: {
                    padding: '.1rem 1rem ',
                    textAlign: 'left',
                    fontWeight: 500,
                    height: '44px',
                  },
                },
              }}
              required
              type='email'
              disabled={loading || maxDepositAmount <= 0}
              value={email}
              onChange={handleEmailInputChange}
              error={!!inputErrors.email}
              helperText={inputErrors.email || ''}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              color='primary'
              type='submit'
              variant='contained'
              disabled={loading || maxDepositAmount <= 0}
            >
              {t('common.button.confirm')}
            </Button>
          </Grid>
        </Grid>
      </form>

      <LoadingBackdrop open={loading} />
      <DepositInfoModal
        open={openInfoModal}
        handleClose={handleCloseInfoModal}
      />
    </>
  )
}

export default DepositPage
