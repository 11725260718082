import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  DepositPage,
  LoginPage,
  PaytrailResultPage,
  ErrorPage,
} from '../../pages'
import { config } from '../../config'
import { AuthComponent } from '../auth-layer/auth-layer.component'

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={config.appRoutes.login} element={<LoginPage />} />
      {[config.appRoutes.success, config.appRoutes.cancel].map(
        (path, index) => {
          return (
            <Route path={path} element={<PaytrailResultPage />} key={index} />
          )
        },
      )}
      <Route element={<AuthComponent />}>
        <Route path={config.appRoutes.deposit} element={<DepositPage />} />
      </Route>
      <Route path={config.appRoutes.error} element={<ErrorPage />} />
      <Route
        path='*'
        element={<Navigate replace to={config.appRoutes.deposit} />}
      />
    </Routes>
  )
}
