import React from 'react'

import { makeStyles } from 'tss-react/mui'
import { AppToolbar } from '../app-toolbar/app-toolbar.component'
import { AppHeader } from '../app-header/app-header.component'
import { AppFooter } from '../app-footer/app-footer.component'
import Grid from '@mui/material/Grid2'
import { Box, Container } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  rootGrid: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      display: 'flex',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  routesWrapper: {
    padding: theme.spacing(4, 3),
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  footerWrapper: {
    marginTop: 'auto',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center',
    },
  },
}))

export const AppLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { classes } = useStyles()

  return (
    <>
      <AppToolbar />
      <Grid container className={classes.rootGrid}>
        <Grid size={{ xs: 12, sm: 6, lg: 5 }}>
          <AppHeader />
        </Grid>
        <Grid
          size={{ xs: 12, sm: 6, lg: 7 }}
          className={classes.contentWrapper}
        >
          <Container className={classes.routesWrapper}>{children}</Container>
          <Box className={classes.footerWrapper}>
            <AppFooter />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
