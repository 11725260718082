import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const ErrorPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Typography variant='h1'>{t('errorPage.title')}</Typography>
      <Box mt={4} />
      <Typography variant='body1'>{t('errorPage.description')}</Typography>
      <Box mt={8} />
      <Button
        variant='contained'
        color='primary'
        onClick={() => navigate('/login')}
      >
        {t('errorPage.button')}
      </Button>
    </>
  )
}
