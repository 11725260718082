import React from 'react'
import { useTranslation } from 'react-i18next'
import { config } from '../../config'
import { MenuItem, Select, Toolbar, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  toolbarRoot: {
    position: 'absolute',
    top: 15,
    right: 7,
    zIndex: 1,
  },
})

export const AppToolbar: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()

  const handleLanguageSelect = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <Toolbar className={classes.toolbarRoot}>
      <Select
        value={i18n.language}
        onChange={e => handleLanguageSelect(e.target.value as string)}
        disableUnderline
      >
        {config.i18n.supportedLngs.map(lng => (
          <MenuItem key={lng} value={lng}>
            <Typography variant='subtitle1'>
              {t(`common.language.${lng}`)}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Toolbar>
  )
}
