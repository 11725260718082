import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import {
  Box,
  Modal,
  Link,
  Typography,
  IconButton,
  Slide,
  useMediaQuery,
  Divider,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CloseIcon from '@mui/icons-material/Close'
import theme from '../../theme'

const useStyles = makeStyles()(theme => ({
  modalContainer: {
    position: 'absolute',
    bottom: 0,
    left: '2.5%',
    transform: 'translateX(-50%)',
    width: '95%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 4, 8),
    borderRadius: '16px 16px 0 0',
    boxShadow: theme.shadows[5],
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      top: '50%',
      left: '50%',
      width: '80%',
      borderRadius: 0,
      transform: 'translate(-50%, -50%)',
      maxWidth: '690px',
      padding: theme.spacing(5),
      height: 'max-content',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {},
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {},
  },
  modalText: {
    textAlign: 'left',
    lineHeight: '1.5rem !important',
    [theme.breakpoints.up('sm')]: {},
  },
  modalTextLink: {
    color: theme.palette.text.secondary,
    textDecorationColor: theme.palette.text.secondary,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {},
  },
}))

interface DepositInfoModalProps {
  open: boolean
  handleClose: () => void
}

export const DepositInfoModal: React.FunctionComponent<
  DepositInfoModalProps
> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const HelpCenterLink = () => {
    return (
      <>
        <Link
          variant='body2'
          href={t('depositPage.infoModal.helpCenterUrl') || '#'}
          target='_blank'
          rel='noopener'
          className={classes.modalTextLink}
        >
          {t('depositPage.infoModal.helpCenterLink')}
        </Link>
      </>
    )
  }

  const ModalContent = (
    <Box className={classes.modalContainer}>
      <Box className={classes.modalHeader}>
        <Typography variant='h1'>{t('depositPage.infoModal.title')}</Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </Box>
      {isMobile && (
        <>
          <Box mt={2} />
          <Divider />
        </>
      )}
      <Box mt={2} />
      <Typography
        variant='subtitle1'
        sx={{ fontWeight: 600 }}
        className={classes.modalText}
      >
        {t('depositPage.infoModal.subtitle')}
      </Typography>
      <Box mt={1} />
      <Typography variant='body1' className={classes.modalText}>
        {t('depositPage.infoModal.desc1')}
      </Typography>
      <Box mt={1} />
      <Typography variant='body1' className={classes.modalText}>
        {t('depositPage.infoModal.desc2')}
      </Typography>
      <Box mt={1} />
      <Typography variant='body2' className={classes.modalText}>
        <Trans
          i18nKey='depositPage.infoModal.desc3'
          components={{
            link: <HelpCenterLink />,
          }}
        />
      </Typography>
    </Box>
  )

  return (
    <Modal open={open} onClose={handleClose}>
      {isMobile ? (
        <Slide
          direction='up'
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={700}
        >
          {ModalContent}
        </Slide>
      ) : (
        ModalContent
      )}
    </Modal>
  )
}

export default DepositInfoModal
