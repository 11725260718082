import { Box, Typography } from '@mui/material'
import React from 'react'
import i18n from '../../i18n'
import { makeStyles } from 'tss-react/mui'
import theme from '../../theme'
import { t } from 'i18next'

const useStyles = makeStyles()({
  heroImage: {
    minHeight: '57vw',
    backgroundSize: 'contain',
    [theme.breakpoints.up('sm')]: {
      minHeight: '100vh',
      backgroundSize: 'cover',
    },
  },
  heroText: {
    position: 'absolute',
    bottom: '1rem',
    right: '51%',
    color: 'white',
    fontSize: '1.9rem !important',
    [theme.breakpoints.up('lg')]: {
      right: '60%',
    },
  },
})

const getBgImage = (lang: string, isDesktop: boolean) => {
  const suffix = lang === 'en' ? '-en' : ''
  return `url(assets/SmartumWallet-hero-${
    isDesktop ? 'desktop' : 'mobile'
  }${suffix}.png)`
}

export const AppHeader: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Box
      className={classes.heroImage}
      sx={{
        backgroundImage: {
          xs: getBgImage(i18n.language, false),
          sm: getBgImage(i18n.language, true),
        },
      }}
    >
      <Typography
        variant='h1'
        className={classes.heroText}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        {t('loginPage.title')}
      </Typography>
    </Box>
  )
}
