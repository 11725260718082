import React from 'react'
import { Backdrop, BackdropProps, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export const LoadingBackdrop: React.FunctionComponent<
  BackdropProps
> = props => {
  const classes = useStyles()

  return (
    <Backdrop classes={classes} {...props}>
      <CircularProgress size='15vw' />
    </Backdrop>
  )
}
