import { createTheme } from '@mui/material/styles'
import { fiFI } from '@mui/material/locale'

const baseTheme = createTheme({
  typography: {
    fontFamily: ['Work Sans', 'Ivar Display'].join(','),
  },
  palette: {
    primary: {
      main: '#CC4928',
    },
    background: {
      default: '#ffffff',
      paper: '#F5F5F5',
    },
    text: {
      primary: '#000',
      secondary: '#727272',
    },
    action: {
      hover: '#c7c7c7',
    },
  },
})

const MuiTheme = createTheme(
  baseTheme,
  {
    typography: {
      h1: {
        color: baseTheme.palette.text.primary,
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: '1.375rem',
        letterSpacing: '-0.005rem',
        [baseTheme.breakpoints.up('sm')]: {
          fontSize: '2rem',
          lineHeight: '2.625rem',
        },
      },
      subtitle1: {
        color: baseTheme.palette.text.primary,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1rem',
        [baseTheme.breakpoints.up('sm')]: {
          fontSize: '1rem',
          lineHeight: '2rem',
        },
      },
      body1: {
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.125rem',
        [baseTheme.breakpoints.up('sm')]: {
          fontSize: '1rem',
          lineHeight: '2rem',
        },
      },
      body2: {
        color: baseTheme.palette.text.secondary,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.125rem',
        [baseTheme.breakpoints.up('sm')]: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
      fontFamily: 'Work Sans',
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(0, 6.625),
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            width: 100,
            height: 40,
            backgroundColor: baseTheme.palette.background.default,
            borderRadius: 8,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            [baseTheme.breakpoints.up('sm')]: {},
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.2rem',
            height: 42,
            padding: baseTheme.spacing(1, 4),
            borderRadius: baseTheme.spacing(5.5),
            [baseTheme.breakpoints.up('sm')]: {
              padding: baseTheme.spacing(2, 6.5),
              fontSize: '1rem',
              lineHeight: '2rem',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiFilledInput-root': {
              borderRadius: baseTheme.spacing(1),
              backgroundColor: baseTheme.palette.background.default,
            },
            '& .MuiFilledInput-adornedStart': {
              paddingLeft: baseTheme.spacing(3),
            },
            '& .MuiFilledInput-adornedEnd': {
              paddingRight: baseTheme.spacing(3),
            },
            '& .MuiFilledInput-input': {
              textAlign: 'center',
              fontWeight: 600,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: baseTheme.palette.action.hover,
            },
          },
        },
      },
    },
  },
  fiFI,
)

export default MuiTheme
